import { Component } from "react";
import Background from '../components/background';

class Layout extends Component {
    render() {
        return (
            <>
                <Background />
                <div className="container">
                    {this.props.children}
                </div>
            </>
        );
    }
}

export default Layout;
