import { Component } from "react";
import Paginator from "./paginator";

class PostList extends Component {
    render() {
        let children = this.props.children;
        let items = [];

        for (let i = 0; i < children.length; i++) {
            items.push(
                children[i]
            )

            if (i !== children.length - 1) {
                items.push(
                    <hr className="divider" />
                )
            }
        }


        return (
            <div className="post-list">
                <hr className="divider" />

                {items}

                <hr className="divider" />

                {
                    this.props.total != 1 ?
                        <Paginator page={this.props.page} total={this.props.total} route={"post"} /> :
                        null
                }
            </div>
        );
    }
}

export default PostList;
