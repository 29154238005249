import { Component } from "react";

class Paginator extends Component {
    render() {
        let { page, total } = this.props;

        if (page == undefined || page == null) {
            page = 1;
        } else {
            page = parseInt(page);
        }

        let items = [];
        if (total < 6) {
            for (let i = 1; i <= total; i++) {
                if (i !== page) {
                    items.push(
                        <div className="paginator-item">
                            <a href={"/" + this.props.route + "?page=" + i}>{i}</a>
                        </div>
                    )
                } else {
                    items.push(
                        <div className="paginator-item paginator-item-select">
                            <a href={"/" + this.props.route + "?page=" + i}>{i}</a>
                        </div>
                    )
                }
            }
        } else if (page < 5 && total >= 6) {
            for (let i = 1; i <= 5; i++) {
                if (i !== page) {
                    items.push(
                        <div className="paginator-item">
                            <a href={"/" + this.props.route + "?page=" + i}>{i}</a>
                        </div>
                    )
                } else {
                    items.push(
                        <div className="paginator-item paginator-item-select">
                            <a href={"/" + this.props.route + "?page=" + i}>{i}</a>
                        </div>
                    )
                }
            }
            items.push(
                <div className="paginator-item">
                    <a >...</a>
                </div>
            )
            items.push(
                <div className="paginator-item">
                    <a href={"/" + this.props.route + "?page=" + total}>{total}</a>
                </div>
            )
        } else if (page > total - 4 && total >= 6) {
            items.push(
                <div className="paginator-item">
                    <a href={"/" + this.props.route + "?page=1"}>1</a>
                </div >
            )
            items.push(
                <div className="paginator-item">
                    <a >...</a>
                </div>
            )

            for (let i = total - 4; i <= total; i++) {
                if (i !== page) {
                    items.push(
                        <div className="paginator-item">
                            <a href={"/" + this.props.route + "?page=" + i}>{i}</a>
                        </div>
                    )
                } else {
                    items.push(
                        <div className="paginator-item paginator-item-select">
                            <a href={"/" + this.props.route + "?page=" + i}>{i}</a>
                        </div>
                    )
                }
            }
        } else if (page >= 5 && page < total - 3) {
            items = [
                <div className="paginator-item">
                    <a href={"/" + this.props.route + "?page=1"}>1</a>
                </div >,
                <div className="paginator-item">
                    <a >...</a>
                </div>,
                <div className="paginator-item">
                    <a href={"/" + this.props.route + "?page=" + (page - 1)}>{page - 1}</a>
                </div>,
                <div className="paginator-item paginator-item-select">
                    <a href={"/" + this.props.route + "?page=" + page}>{page}</a>
                </div>,
                <div className="paginator-item">
                    <a href={"/" + this.props.route + "?page=" + (page + 1)}>{page + 1}</a>
                </div>,
                <div className="paginator-item">
                    <a >...</a>
                </div>,
                <div className="paginator-item">
                    <a href={"/" + this.props.route + "?page=" + total}>{total}</a>
                </div>,
            ]
        }
        return (
            <div className="paginator">
                {
                    page <= 1 ? null :
                        <div className="paginator-item">
                            <a href={"/" + this.props.route + "?page=" + (page - 1)}>&lt;</a>
                        </div>
                }

                {items}

                {
                    page >= total ? null :
                        <div className="paginator-item">
                            <a href={"/" + this.props.route + "?page=" + (page + 1)}>&gt;</a>
                        </div>
                }
            </div >
        );
    }
}

export default Paginator;
