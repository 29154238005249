import { Component } from 'react';

class Header extends Component {
    render() {
        return (
            <header className="header">
                <h1>白夜的幻想乡缘起</h1>
                <p className="nav">
                    <a href="/">
                        主页
                    </a> | <a href="/category">
                        分类
                    </a> | <a href="/about">
                        关于
                    </a> | <a href="/friends">
                        友链
                    </a> | <a href="/feed">
                        RSS
                    </a>
                </p>
            </header>
        );
    }
}

export default Header;
