import { Component } from 'react';
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';
import FriendCard from '../components/friend_card';
import FriendList from '../components/friend_list';

import axios from 'axios';

class FriendsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        }

        axios({
            method: "get",
            url: "/api/v1/friend",
        }).then((response) => {
            console.log(response.data);
            this.setState({
                data: response.data.data.list,
            });
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    render() {
        let data = this.state.data
        return (
            <Layout>
                <Header />


                <FriendList>
                    {
                        data.map((item) => {
                            return <FriendCard url={item.url} avatar={item.avatar_url} name={item.name} description={item.description} />
                        })
                    }

                </FriendList>

                <Footer />
            </Layout>
        );
    }
}

export default FriendsPage;
