import { Component } from "react";
import Layout from "../components/layout";
import Header from "../components/header";
import withRouter from "../utils/with_router";
import Footer from '../components/footer';
import axios from 'axios';
import Paginator from "../components/paginator";

const test_data = {
    total_page: 10,
    list: [
        {
            id: 1,
            name: "aaaa",
        },
        {
            id: 1,
            name: "aaaa",
        },
        {
            id: 1,
            name: "aaaa",
        },
    ]
}

class CategoryListPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                total_page: 0,
                list: [],
            },
        }

        axios({
            method: 'get',
            url: '/api/v1/category',
            params: {
                page: this.props.searchParams.get("page") == null ||
                    this.props.searchParams.get("page") == undefined ||
                    this.props.searchParams.get("page") == "" ?
                    "1" :
                    this.props.searchParams.get("page"),

            }
        }).then((response) => {
            this.setState({
                data: response.data.data,
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        let data = this.state.data;
        // let data = test_data;

        return (
            <Layout>
                <Header />

                <hr className="divider" />

                <h2 className="category-list-title">分类</h2>

                <div className="category-list">
                    <ul>
                        {
                            data.list.map((category) => {
                                return (
                                    <li>
                                        <a href={"/category/" + category.id}>{category.name}</a>
                                    </li>
                                )
                            })
                        }
                    </ul>

                </div>


                <hr className="divider" />

                {
                    data.total_page != 1 ?
                        <Paginator page={this.props.searchParams.get("page")} total={data.total_page} route={"category"} /> :
                        null
                }

                <Footer />
            </Layout>
        );
    }
}

export default withRouter(CategoryListPage);
