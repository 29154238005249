import { Component } from "react";

class FriendList extends Component {
    render() {
        let children = this.props.children;
        let items = [];

        for (let i = 0; i < children.length; i++) {
            items.push(
                children[i]
            )

            if (i !== children.length - 1) {
                items.push(
                    <hr className="divider" />
                )
            }
        }


        return (
            <div className="friend-list">
                <hr className="divider" />

                {items}


                <hr className="divider" />
            </div>
        );
    }
}

export default FriendList;
