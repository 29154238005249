import { useParams, useSearchParams } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();

    return (
        <WrappedComponent
            {...props}
            {...{ searchParams, setSearchParams, params }}
        />
    );
};

export default withRouter;
