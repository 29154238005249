import { Component } from "react";

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <p>&copy; 2021 白夜的幻想乡缘起</p>
                <p><a href="https://icp.gov.moe/?keyword=20246405" target="_blank">萌ICP备20246405号</a></p>
            </footer>
        );
    }
}

export default Footer;
