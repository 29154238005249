import { Component } from "react";
import { Route, Routes } from "react-router";
import IndexPage from "./pages/index_page";
import PostPage from "./pages/post_page";
import CategoryListPage from "./pages/category_list_page";
import CategoryPage from "./pages/category_page";
import AboutPage from "./pages/about_page";
import FriendsPage from "./pages/friends_page";

class App extends Component {
    render() {
        return (
            <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/post" element={<IndexPage />} />
                <Route path="/post/:id" element={<PostPage />} />
                <Route path="/category" element={<CategoryListPage />} />
                <Route path="/category/:id" element={<CategoryPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/friends" element={<FriendsPage />} />
            </Routes>
        );
    }
}

export default App;
