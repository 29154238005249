import { Component } from "react";

class Post extends Component {
    render() {
        let date = new Date(this.props.date);
        date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        return (
            <>
                <h2 className="post-title">
                    <a href={"/post/" + this.props.post_id}>
                        {this.props.title}
                    </a>
                </h2>

                <p className="post-info">
                    <a href={"/category/" + this.props.category_id}> {this.props.category} </a> | {date}
                </p>

                <article className="content" >
                    <div dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
                </article >
            </>
        );
    }
}

export default Post;
