import { Component } from 'react';
import withRouter from '../utils/with_router';
import Layout from '../components/layout';
import Header from '../components/header';
import Post from '../components/post';
import Footer from '../components/footer';
import axios from 'axios';

const test_data = {
    "id": 2,
    "title": "aaaaaaaa",
    "category": {
        "id": 1,
        "name": "aaa"
    },
    "date": "2023-08-01",
    "content": "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
};

class PostPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                "id": 0,
                "title": "",
                "category": {
                    "id": 0,
                    "name": ""
                },
                "date": "",
                "content": ""
            },
        };

        axios({
            method: "get",
            url: "/api/v1/post/" + this.props.params.id,
        }).then((response) => {
            console.log(response.data);
            this.setState({
                data: response.data.data,
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        let data = this.state.data;
        // let data = test_data;

        return (
            <Layout>

                <Header />

                <hr className="divider" />

                <Post
                    post_id={data.id}
                    category_id={data.category.id}
                    title={data.title}
                    category={data.category.name}
                    date={data.date}
                    content={data.content}
                />

                <hr className="divider" />

                <Footer />
            </Layout>
        );

    }
}

export default withRouter(PostPage);
