import { Component } from "react";
import Layout from "../components/layout";
import Header from "../components/header";
import Footer from "../components/footer";

class AboutPage extends Component {
    render() {
        return (
            <Layout>
                <Header />

                <hr className="divider" />

                <img src="/avatar.png" alt="Hakuya" id="avatar" />

                <h2 className="post-title">
                    <a href="#">
                        Hakuya
                    </a>
                </h2>

                <article className="content" >
                    <h2>Who am I?</h2>
                    <ul>
                        <li>Vidar-Team的21级主力pwn手<del>，喜欢折腾IoT，然而没什么拿得出手的成果</del>。</li>
                        <li>某电子科技大专本科生<del>，是个中外合作的大怨种</del>。</li>
                        <li><del>太菜了，写不出东西了。。。</del></li>
                    </ul>

                    <h2>More info</h2>
                    <ul>
                        <li>Linux kernel pwn学习中<del>，已经拖了一年了，还是没学会。。。</del></li>
                        <li>折腾路由器，最近在计划加入DN42</li>
                        <li>是个车车人，但是连Normal都打不完<del>，恋恋真可爱，嘿嘿嘿，🤤🤤🤤</del></li>
                        <li>傻逼管人痴，<span id="shabi"></span>。</li>
                    </ul>

                </article >

                <hr className="divider" />

                <Footer />
            </Layout>
        );
    }
}

export default AboutPage;
