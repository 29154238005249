import { Component } from 'react';
import Layout from "../components/layout";
import Header from "../components/header";
import PostList from "../components/post_list";
import Post from "../components/post";
import withRouter from "../utils/with_router";
import Footer from '../components/footer';
import axios from 'axios';

const test_data = {
    total_page: 1,
    list: [
        {
            id: 1,
            title: "白夜的幻想乡缘起",
            category: {
                id: 1,
                name: "杂谈",
            },
            date: "2021-08-01",
            description: "这是一篇测试文章",
        },
    ]
}

class CategoryPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                total_page: 0,
                list: [],
            },
        };

        axios({
            method: "get",
            url: `/api/v1/category/${this.props.params.id}`,
            params: {
                page: this.props.searchParams.get("page") == null ||
                    this.props.searchParams.get("page") == undefined ||
                    this.props.searchParams.get("page") == "" ?
                    "1" :
                    this.props.searchParams.get("page"),
            },
        }).then((response) => {
            console.log(response);
            this.setState({
                data: response.data.data,
            });
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    render() {
        let searchParams = this.props.searchParams;
        let page = searchParams.get("page");

        let data = this.state.data;
        console.log(data);
        // let data = test_data;

        return (
            <Layout>
                <Header />

                <PostList page={page} total={data.total_page}>
                    {
                        data.list.map((item) => {
                            return (
                                <Post
                                    post_id={item.id}
                                    category_id={item.category.id}
                                    title={item.title}
                                    category={item.category.name}
                                    date={item.date}
                                    content={`<p>${item.description}</p>`}
                                />
                            );
                        })
                    }
                </PostList>

                <Footer />
            </Layout>
        );
    }

}

export default withRouter(CategoryPage);
