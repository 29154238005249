import { Component } from 'react';

class FriendCard extends Component {
    render() {
        return (
            <div className="friend-card" onClick={() => { window.location = this.props.url }}>
                <img src={this.props.avatar} alt={this.props.name} className="friend-avatar" />
                <div className="friend-info">
                    <h2 className="friend-name">{this.props.name}</h2>
                    <p className="friend-description">{this.props.description}</p>
                </div>
            </div>
        );
    }
}

export default FriendCard;
