import { Component } from 'react';

class Background extends Component {
    render() {
        return (
            <div className="background" style={{ backgroundImage: 'url(/background.jpg)' }} />
        );
    }
}

export default Background;
